@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  margin:0;
  font-family: 'Prompt', sans-serif;
  scroll-behavior: smooth;
}

.font-main{
  font-family: 'Prompt', sans-serif;
}

.bg-postperty{
  background: linear-gradient(258.56deg, #189eea 30.48%, #00b8d2 88.16%);
}

.modal-open {
  backdrop-filter: blur(8px);
}

.text-gd-postperty {
  background: linear-gradient(258.56deg, #189eea 30.48%, #00b8d2 88.16%);
  -webkit-background-clip: text; /* For Safari/Chrome/iOS */
  background-clip: text;
  color: transparent;
}


@keyframes moveDownSmooth {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.moveDownSmooth {
  animation: moveDownSmooth 0.25s ease-in-out;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
